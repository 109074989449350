import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { verifyToken } from '../apiService';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      const token = sessionStorage.getItem('id_token');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      const isValid = await verifyToken(token);
      setIsAuthenticated(isValid);
    };
   
    checkToken();
  }, []);

  if (isAuthenticated === null) {
    // Optionally, you can show a loading spinner or some other UI here while checking the token
    return <div>Loading...</div>;
  }
  
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;