import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import loadingImage from '../images/loading_code.gif';
import { convertCode } from '../apiService';

const CodeConverter = () => {
  const [targetLanguage, setTargetLanguage] = useState('');
  const [customLanguage, setCustomLanguage] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [convertedCode, setConvertedCode] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for managing loading state

  const handleConvert = async () => {
    setIsLoading(true); 
    setConvertedCode('')
    try {
      
      const language = targetLanguage === 'other' ? customLanguage : targetLanguage;
      const data = await convertCode(inputCode,language);
      const cleanedResponse = data.translatedCode.replace(/^```|```$/g, '');
      setConvertedCode(cleanedResponse); // Adjust according to API response structure
    } catch (error) {
      console.error('Error converting code:', error);
      setConvertedCode('Error converting code. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const copyToClipboard = async () => {
    if (convertedCode) {
      try {
        await navigator.clipboard.writeText(convertedCode);
        // alert('Code copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
        // alert('Failed to copy code to clipboard');
      }
    }
  };
  return (
    <div className="container mt-5">
      <div className="mb-4">
        <h1>Code Converter</h1>
        <p className="text-muted">Easily convert code between different programming languages.</p>
      </div>
      <div className="mb-3">
        <label htmlFor="inputCode" className="form-label">Enter Your Code</label>
        <textarea id="inputCode" className="form-control" value={inputCode} onChange={e => setInputCode(e.target.value)} placeholder="Enter your code here..." rows="5" />
      </div>
      <div className="mb-3">
        <label htmlFor="targetLanguage" className="form-label">Select a Target Language</label>
        <select id="targetLanguage" className="form-select" value={targetLanguage} onChange={e => setTargetLanguage(e.target.value)}>
          <option value="">Select a Target Language</option>
          <option value="go">Golang</option>
          <option value="java">Java</option>
          <option value="rust">Rust</option>
          <option value="other">Other</option>
          {/* Add more options as needed */}
        </select>
        {targetLanguage === 'other' && (
          <div className="mt-3">
            <label htmlFor="customLanguage" className="form-label">Enter Custom Language</label>
            <input id="customLanguage" className="form-control" value={customLanguage} onChange={e => setCustomLanguage(e.target.value)} placeholder="Custom language" />
          </div>
        )}
      </div>
      <div className="text-center mb-3">
        <button className="btn btn-primary" onClick={handleConvert} disabled={isLoading}>Convert</button>
      </div>
      {isLoading ? (
        <div className="loading-container">
          <img src={loadingImage} alt="Loading..." /> {/* Update the src attribute as needed */}
        </div>
      )  : convertedCode ? ( // This line checks if convertedCode has a value
      <div style={{ position: 'relative' }}>
      <button 
        style={{ 
          position: 'absolute', 
          right: 0, 
          top: -10, 
          zIndex: 1
        }} 
        className="btn btn-dark" 
        onClick={copyToClipboard}>
          Copy Code
      </button>
      <div className="mb-3">
        <label className="form-label">Converted Code</label>
        <SyntaxHighlighter 
          // language={targetLanguage} 
          style={a11yDark}
          showLineNumbers={true}
          wrapLongLines={true}
          wrapLines={true}>
            {convertedCode}
        </SyntaxHighlighter>
      </div>
    </div>
    ) : null }
    </div>
  );
};

export default CodeConverter;
