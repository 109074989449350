import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../apiService';
const Callback = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setCode(params.get('code'));
    }, []);
    
    useEffect(() => {
        const exchangeCode = async () => {
            try {
                if (code) {
                    const response = await getToken(code);
                    const { id_token, access_token, refresh_token } = response;
                    sessionStorage.setItem('id_token', id_token);
                    sessionStorage.setItem('access_token', access_token);
                    sessionStorage.setItem('refresh_token', refresh_token);
                    navigate('/');

                }
            } catch (error) {
                console.error('Error exchanging code:', error);
                navigate('/login'); // On failure, redirect back to login
            }
        };

        exchangeCode();
    }, [code, navigate]);

    return <div>Loading...</div>;
};

export default Callback;
