import React, { useState } from 'react';
import { fetchData } from '../apiService';
import SummaryChart from '../CPUComparisonChart';
import DataTables from '../DataTables';
import SummaryDataTables from '../DataTablesSummary';
import LimitsForm from '../Forms/LimitsForm';
// import TriggerForms from './triggerForm';
import loadingImage from '../images/loading.gif';


const PrimeCalculatorAdvanced = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const handleFormSubmit = async (language,architecture, limits) => {
    setIsLoading(true); // Start loading
    try {
        //add condition if limits if empty then set default value to 5 10
        console.log(typeof limits)
        if (limits === "") {
            console.log("empty")
            limits = "5 10"
        }
        const newResult = await fetchData(language, architecture, limits);
        if (language === "py") {
            language = "Python"
        } else if (language === 'go') {
            language = "GO"
        }

        if (architecture === "amd64") {
            architecture = "x86"
        } else if (architecture === 'arm64') {
            architecture = "Graviton"
        }

        const sessionName = `${language} + ${architecture}`;
        const resultWithSession = { ...newResult, sessionName };
        setData((prevData) => {

        if (prevData.length === 0) {
            return [...prevData, resultWithSession];
        }
        const combinedData = [...prevData, resultWithSession];
        // Pass the combined array to calculatePercentageDifferences
        const processedData = calculatePercentageDifferences(combinedData);
        console.log(processedData)
        return  processedData;
        
        }); 
        // setSessionName(sessionName)
    } catch (error) {
        console.error('Failed to fetch data:', error);
    } finally {
        setIsLoading(false)
    }
    };

    const calculatePercentageDifferences = (data) => {
    // Assuming data is sorted by session or time
    return data.map((item, index) => {
    if (index === 0) { // No previous data to compare for the first item
        return { ...item, cpuTimeDiff: null, costDiff: null };
    }

    const prevItem = data[index - 1];
    console.log(prevItem)
    const cpuTimeDiff = ((item.total_cpu_time - prevItem.total_cpu_time) / prevItem.total_cpu_time) * 100;
    const costDiff = ((parseFloat(item.total_cost) - parseFloat(prevItem.total_cost)) / parseFloat(prevItem.total_cost)) * 100;

    return { ...item, cpuTimeDiff, costDiff };
    });
    };

    const toggleDetails = () => {
    setShowDetails(prevShowDetails => !prevShowDetails);
    };

    const handleClearData = () => {
    setData([]);
    // setSessionNumber(1);
    };

    return (
    <div className="container mt-4">
        {/* <h1>The Performance Race</h1> */}
        {/* <RaceTrack racers={racers} /> */}
        <h1>Prime Calculator</h1>
        <h3>Cost & Performance Analysis Dashboard</h3>
        <p>This application calculates the sum of prime numbers up to a specified limit using concurrent processing for enhanced performance. It measures execution time and computes the cost, providing a fast and cost-efficient solution for prime number summation tasks.</p>
        <LimitsForm onSubmit={handleFormSubmit} onClear={handleClearData} />
        {/* <TriggerForms onSubmit={handleFormSubmit} onClear={handleClearData} /> */}

        {isLoading &&<div className="loading-container">
        <img src={loadingImage} alt="Loading..." />
        </div>}
        {!isLoading && data.length > 0 &&
        <>
            <SummaryChart data={data} />
            <SummaryDataTables data={data} />
            <h3 onClick={toggleDetails} style={{cursor: 'pointer'}}>Session Details {showDetails ? '▼' : '▶'}</h3>
        {showDetails && (
            <>
            <DataTables data={data} />
            </>
        )}
        </>
        }
    </div>
    )
};
export default PrimeCalculatorAdvanced;