import dotenv from 'dotenv';
import axios from 'axios';

dotenv.config(); // Ensure this is at the top of your script

const getBaseUrl = (architecture,language) => {
  const host = window.location.origin
  const hostname = host.split('//')[1].split(':')[0]
  if (hostname === 'localhost') {
    var port = null
    switch (language) {
      case 'py':
        port = ':8081';
        break;
      case 'go':
        port = ':8080';
        break;
      case 'java':
        port = ':8082';
        break;
      default:
        return 'unknown language'
    }
    
    return `http://${hostname}${port}`
  }
  const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
  const protocol = 'https://api';
  return `${protocol}.${architecture}.${baseDomain}`;
};


export const fetchData = async (language, architecture, limits) => {
  try {
    
    // Determine the url based on the architecture and language provided
    const BASE_URL = getBaseUrl(architecture === 'arm64' ? 'arm' : 'x86',language);
    // Dynamically construct params object
    const params = {};
    if (limits) {
      params.limits = limits; // Only add limits parameter if it is not empty
    }
    var token = sessionStorage.getItem('id_token'); 
    const fullUrl = `${BASE_URL}/${language}/calculate`
    const response = await axios.get(fullUrl, {
      params,
      headers: {
        'Authorization':`Bearer ${token}`
      },
    });

    return response.data;
  } catch (error) {
    console.log(error.response.status);
    console.error('There was an error fetching the data:', error);
    throw error;
  }
};

export const convertCode = async (inputCode,language) => {
  try {
    var token = sessionStorage.getItem('id_token'); 
    const BASE_URL = getBaseUrl('x86','py');
    const response = await fetch(`${BASE_URL}/py/convert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      },
      body: JSON.stringify({ "code": inputCode, "language" : language }),
    });
    const data = await response.json();
    return data; 
  } catch (error) {
    console.error('There was an error fetching the data:', error);
    throw error;
  }
};

export const verifyToken = async (token) => {
  try {
    const BASE_URL = getBaseUrl('x86','py');
    const fullUrl = `${BASE_URL}/py/validate`
    const response = await axios.get(fullUrl, {
      headers: {
        'Authorization':`Bearer ${token}`
      },
    });
    return response.data.status;
  } catch (error) {
    console.error('Token verification failed:', error);
    return false;
  }
};

export const getToken = async (code) => {
  try {
    const BASE_URL = getBaseUrl('x86','py');
    const fullUrl = `${BASE_URL}/exchange-code`
    const response = await axios.post(fullUrl, { code });
    return response.data;
  } catch (error) {
    console.error('Token verification failed:', error);
    return false;
  }
};