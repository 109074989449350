import React, { useEffect } from 'react';

const Login = () => {
    useEffect(() => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const userPoolDomain = process.env.REACT_APP_USER_POOL_DOMAIN;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_CALLBACK_URL);
    const responseType = 'code'; 
    const loginUrl = `${userPoolDomain}/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
}, []);

return (
    <div>Loading...</div> 
  );
};

export default Login;

