import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SummaryChart = ({ data }) => {
  // Preparing data for the chart
  const chartData = {
    labels: data.map((item, index) => `${item.sessionName}`),
    datasets: [
      {
        label: 'Total CPU Time',
        data: data.map(item => item.total_cpu_time),
        backgroundColor: 'rgba(255, 153, 0, 0.5)', // Amazon Orange with transparency
        borderColor: '#FF9900', // Amazon Orange
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'Total Cost',
        data: data.map(item => item.total_cost),
        backgroundColor: 'rgba(20, 110, 180, 0.5)', // Amazon Green (approximation) with transparency
        borderColor: '#146EB4', // Amazon Green (approximation)
        borderWidth: 1,
        yAxisID: 'y1',
      }
    ],
  };

  const options = {
    plugins: {
      legend: { 
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 10,
          font : {
            size : 15
          }
        }
      },
      tooltip: {
        titleFont: { size: 17,weight:'bold', family: "sans-serif" },
        bodyFont: { size: 16, family: "sans-serif" },
        callbacks: {
          labels: (context) => {
          },
          afterBody: (context) => {
            const dataIndex = context[0].dataIndex;
            console.log(dataIndex)
            const cpuTimeDiff = data[dataIndex].cpuTimeDiff;
            const costDiff = data[dataIndex].costDiff;
            const cost = data[dataIndex].total_cost;
            const cpuTime = data[dataIndex].total_cpu_time;
            return [
              `Cost: $${cost}`,
              `CPU Time: ${parseFloat(cpuTime).toFixed(2)}s`,
              `CPU Time Diff: ${cpuTimeDiff !== null ? Math.round(cpuTimeDiff) + '%' : 'N/A'}`,
              `Cost Diff: ${costDiff !== null ? Math.round(costDiff) + '%' : 'N/A'}`,
            ];
          }
        }
      }
    },
    scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
  };

  return <Bar data={chartData} options={options} />;
};

export default SummaryChart;
