import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import CodeConverter from './components/CodeConverter'; 
import PrimeCalculator from './components/PrimeCalculator'; 
import PrimeCalculatorAdvanced from './components/PrimeCalculatorAdvanced'; 
import ProtectedRoute from './components/ProtectedRoute';
import Callback from './auth/Callback';
import Login from './components/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const App = () => {

return (
    <Router>
      <Routes>
        <Route path="/" element={ <ProtectedRoute><PrimeCalculator /></ProtectedRoute>}  />
        <Route path="/codeconverter" element={<ProtectedRoute><CodeConverter /></ProtectedRoute>}  />
        <Route path="/advanced" element={<ProtectedRoute><PrimeCalculatorAdvanced /></ProtectedRoute>}  />
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
      </Routes>
    </Router>
  );

};

export default App;
