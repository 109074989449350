import React from 'react';

const SummaryDataTables = ({ data }) => (
  <div className="table-responsive">
    <h3>Summary Data</h3>
    <table className="table">
      <thead>
        <tr>
          <th>Session #</th>
          <th>Language</th>
          <th>Instance Type</th>
          <th>Total CPU Time</th>
          <th>Total Cost</th>
          <th>Instance Region</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.sessionName}</td> {/* Display the session number */}
            <td>{item.language}</td>
            <td>{item.instance_type}</td>
            <td>{item.total_cpu_time}</td>
            <td>{item.total_cost}</td>
            <td>{item.instance_region}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default SummaryDataTables;
