import React from 'react';

const DataTables = ({ data }) => (
  <div className="table-responsive">

    <table className="table">
      <thead>
        <tr>
          <th>Session #</th>
          <th>Language</th>
          <th>Instance Type</th>
          <th>Limit</th>
          <th>Sum</th>
          <th>Execution Time</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) =>
          item.session_details.map((detail, index) => (
            <tr key={index}>
              <td>{item.sessionName}</td> {/* Display the session number */}
              <td>{item.language}</td>
              <td>{item.instance_type}</td>
              <td>{detail.limit}</td>
              <td>{detail.sum}</td>
              <td>{detail.execution_time}</td>
              <td>{detail.cost}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
);

export default DataTables;
