import React from 'react';
import arm64Image from '../images/logo_aws_graviton.png';
import goImage from '../images/logo_go.png';
import amd64Image from '../images/logo_intel.png';
import pythonImage from '../images/logo_py.png';
import javaImage from '../images/logo_java.png';

const TriggerForms = ({ onSubmit, onClear }) => {

    // Handler for scenario buttons
    const handleScenario = (language, architecture) => {
        onSubmit(language, architecture);
    };

    return (
        <div className="mb-6">
          <div className="scenario-selectors">
                <div className="scenario" onClick={() => handleScenario('py', 'amd64')}>
                    <img src={pythonImage} alt="Python" /> + 
                    <img src={amd64Image} alt="amd64" />
                </div>
                <div className="scenario" onClick={() => handleScenario('py', 'arm64')}>
                <img src={pythonImage} alt="Python" /> + 
                    <img src={arm64Image} alt="arm64" />
                </div>
                <div className="scenario" onClick={() => handleScenario('go', 'amd64')}>
                    <img src={goImage} alt="Go" /> + 
                    <img src={amd64Image} alt="amd64" />
                </div>
                <div className="scenario" onClick={() => handleScenario('go', 'arm64')}>
                <img src={goImage} alt="Go" /> + 
                    <img src={arm64Image} alt="arm64" />
                </div>
                <div className="scenario" onClick={() => handleScenario('java', 'amd64')}>
                <img src={javaImage} alt="Java" /> + 
                    <img src={amd64Image} alt="amd64" />
                </div>
                <div className="scenario" onClick={() => handleScenario('java', 'arm64')}>
                <img src={javaImage} alt="Java" /> + 
                    <img src={arm64Image} alt="arm64" />
                </div>
            </div>
            {/* <button type="button" className="btn btn-secondary" onClick={handleClear}>Clear</button> */}
        </div>
    );
};

export default TriggerForms;
