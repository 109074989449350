// LimitsForm.js
import React, { useState } from 'react';

const LimitsForm = ({ onSubmit,onClear }) => {
    const [limits, setLimits] = useState('');
    const [language, setLanguage] = useState('py'); // Default to python
    const [architecture, setArchitecture] = useState('amd64'); // Default to amd64

const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(language, architecture, limits); // Pass language, architecture, and limits to onSubmit
      };

  // Clear form fields when the "Clear" button is clicked
  const handleClear = () => {
    setLimits('');
    setLanguage('py'); // Reset to default or another preferred value
    setArchitecture('amd64'); // Reset to default or another preferred value
    onClear(); // Call the passed onClear function from the parent component
  };

  return (
    <form onSubmit={handleSubmit} className="mb-3">
    <div className="mb-3">
      <label htmlFor="languageSelect" className="form-label">Language</label>
      <select
        id="languageSelect"
        className="form-select"
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
      >
        <option value="py">Python</option>
        <option value="go">Golang</option>
        <option value="java">Java</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="architectureSelect" className="form-label">Architecture</label>
      <select
        id="architectureSelect"
        className="form-select"
        value={architecture}
        onChange={(e) => setArchitecture(e.target.value)}
      >
        <option value="amd64">x86</option>
        <option value="arm64">Graviton</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="limitsInput" className="form-label">
        Enter Limits (space-separated)
      </label>
      <input
        type="text"
        className="form-control"
        id="limitsInput"
        placeholder="e.g., 10 20 5 (leave empty for randomly generated values)"
        value={limits}
        onChange={(e) => setLimits(e.target.value)}
      />
    </div>
    <button type="submit" className="btn btn-primary">
      Submit
    </button>
    
    <button type="button" className="btn btn-secondary" onClick={handleClear}>Clear</button>
  </form>
);
};

export default LimitsForm;
